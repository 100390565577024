<template>
  <div class="h-screen flex flex-col lg:flex-row items-center justify-center">
    <div class="w-7/12 h-screen shadow-2xl hidden lg:flex aic jcc">
      <img
        src="/img/login-4.webp"
        alt="login"
        class="w-full h-screen object-cover mirror"
      >
    </div>
    <div class="flex flex-col items-center justify-start w-full lg:w-5/12 h-screen overflow-y-scroll p-7 lg:p-12">
      <div class="flex aic jcs mb-16 w-full">
        <span class="w-5 h-12 bg-brown-lightest shadow-xl"></span>
        <router-link
          to="/"
          class="flex flex-col ais jcc ml-5"
        >
          <small class="fs-10 text-gray-600 tracking-widest uppercase">Brötchen Shop</small>
          <h1 class="roboto-bold text-xl md:text-2xl">{{$t("Register")}}</h1>
        </router-link>
        <router-link
          to="/"
          class="ml-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 md:w-5 md:h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
          </svg>
        </router-link>
      </div>
      <form
        @submit.prevent="signup"
        class="my-auto w-full max-w-2xl auth-scale"
      >
        <div class="flex flex-col auth-area">
          <div class="rounded-4xl text-left transform transition-all">
            <div>
              <!-- <div class="px-2">
                <div class="flex aic jcb mb-16 bg-gray-100 p-4 text-gray-800 auth-border auth-header animation a1 shadow-lg">
                  <span class="auth-text-top uppercase b">{{$t("Register")}}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                    />
                  </svg>
                </div>
              </div> -->
              <div class="flex flex-wrap form">
                <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 p-2">
                  <div>
                    <input
                      type="text"
                      name="name"
                      id="registerName"
                      placeholder="Name"
                      required
                      v-model="user.name"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Name"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 p-2">
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email-address"
                      placeholder="Email"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      required
                      v-model="user.email"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Email"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 p-2">
                  <div>
                    <input
                      type="text"
                      name="address"
                      id="street"
                      :placeholder="$t('Street')"
                      required
                      v-model="user.address"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Email"
                      autocomplete="email"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 p-2">
                  <div>
                    <input
                      type="text"
                      name="postal_code"
                      id="postal_code"
                      :placeholder="$t('Postal Code')"
                      required
                      v-model="user.postal_code"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Postal Code"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 p-2">
                  <div>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      required
                      v-model="user.password"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Password"
                      :placeholder="$t('Password')"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-full xl:w-1/2 p-2">
                  <div>
                    <input
                      type="password"
                      name="password2"
                      id="password2"
                      required
                      v-model="confPassword"
                      class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                      aria-label="Password"
                      :placeholder="$t('Confirm Password')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row lg:flex-col xl:flex-row space-y-8 md:space-y-0 lg:space-y-8 xl:space-y-0 aic jcb mb-16 lg:mb-32 mt-12 lg:mt-24">
              <div class="flex flex-col ais jcb w-full">
                <span class="text-sm">{{$t("Already have an account")}}</span>
                <router-link
                  to="/login"
                  class="a-black text-sm cursor-pointer b text-left"
                >{{$t("Login here!")}}</router-link>
              </div>
              <button
                type="submit"
                class="inline-flex nunitoSans-bold justify-center w-full md:max-w-max lg:max-w-2xl xl:max-w-max border border-transparent relative px-16 py-3 btn-brown-light-rev"
              >
                {{ $t("Register") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
      confPassword: null,
    };
  },
  computed: {
    restaurantsList() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurants;
    },
  },
  methods: {
    signup() {
      if (this.confPassword == this.user.password) {
        this.$store
          .dispatch("restaurant/getRestaurants", {
            postalCode: this.user.postal_code,
            deliveryType: process.env.VUE_APP_DELIVERY,
            limit: 1,
            status: "all",
          })
          .then(() => {
            if (this.restaurantsList && this.restaurantsList.length) {
              this.user.restaurantId = this.restaurantsList[0].id;
              localStorage.setItem("restaurantId", this.restaurantsList[0].id);
              this.$store.dispatch("auth/signup", this.user).then((res) => {
                this.$store.commit("setshowNotif", {
                  show: true,
                  msg: res.msg,
                  type: res.type,
                });
                if (res.type == "success")
                  setTimeout(() => {
                    this.$router.push("/dashboard");
                  }, 3000); // TODO optimize it in api package
              });
            } else
              this.$store.commit("setshowNotif", {
                show: true,
                msg: "there is not any bakery in your area.",
                type: "error",
              });
          });
      } else {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: "Password doesn't match",
          type: "error",
        });
      }
    },
  },
};
</script>

<style></style>
